var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

// Support for 2.13 - 2.14
var _Ember$__loader$requi = Ember.__loader.require('ember-glimmer/syntax'),
    registerMacros = _Ember$__loader$requi.registerMacros;

var _Ember$__loader$requi2 = Ember.__loader.require('@glimmer/runtime'),
    compileArgs = _Ember$__loader$requi2.compileArgs;

registerMacros(function (blocks) {
  blocks.add('let', function (sexp, builder) {
    var _sexp = _slicedToArray(sexp, 5),
        params = _sexp[2],
        hash = _sexp[3],
        _default = _sexp[4];

    var args = compileArgs(params, hash, builder);

    builder.putArgs(args);

    builder.labelled(null, function (b) {
      b.evaluate(_default);
    });
  });
});