define("ember-bootstrap/components/base/bs-modal/header/close", ["exports", "ember-bootstrap/templates/components/bs-modal/header/close"], function (_exports, _close) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   @class ModalHeaderClose
   @namespace Components
   @extends Ember.Component
   @private
   */
  var _default = Ember.Component.extend({
    layout: _close.default,
    tagName: 'button',
    classNames: ['close'],
    attributeBindings: ['type', 'aria-label'],
    'aria-label': 'Close',
    type: 'button',

    /**
     * @event onClick
     * @public
     */
    onClick: function onClick() {},
    click: function click() {
      this.get('onClick')();
    }
  });

  _exports.default = _default;
});