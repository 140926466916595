define("ember-bootstrap/utils/cp/overrideable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = overrideableCP;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  /**
   * CP macro that created a regular computed property, which can be manually overriden.
   * This is needed after implicitly overrideable CPs have been deprecated:
   * https://deprecations-app-prod.herokuapp.com/deprecations/v3.x/#toc_computed-property-override
   *
   * @private
   */
  function overrideableCP() {
    var fn = Array.prototype.slice.call(arguments, -1)[0];
    var args = Array.prototype.slice.call(arguments, 0, arguments.length - 1);
    (true && !(typeof fn === 'function') && Ember.assert('Last argument for overrideableCP must be a function', typeof fn === 'function'));
    return Ember.computed.apply(void 0, _toConsumableArray(args).concat([{
      get: function get(key) {
        var overridden = this["__".concat(key)];
        return overridden || fn.call(this);
      },
      set: function set(key, value) {
        this["__".concat(key)] = value;
        return value;
      }
    }]));
  }
});