define("ember-bootstrap/components/bs-modal/dialog", ["exports", "ember-bootstrap/components/base/bs-modal/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dialog.default.extend({
    classNameBindings: ['showModal:show'],
    centered: false
  });

  _exports.default = _default;
});