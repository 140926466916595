define("ember-bootstrap/components/base/bs-accordion/item/title", ["exports", "ember-bootstrap/templates/components/bs-accordion/title"], function (_exports, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   Component for an accordion item title.
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionTitle
   @namespace Components
   @extends Ember.Component
   @public
   */
  var _default = Ember.Component.extend({
    layout: _title.default,
    ariaRole: 'tab',
    classNameBindings: ['collapsed:collapsed:expanded'],

    /**
     * @property collapsed
     * @type boolean
     * @public
     */

    /**
     * @property disabled
     * @type boolean
     * @private
     */

    /**
     * @event onClick
     * @public
     */
    onClick: function onClick() {},
    click: function click(e) {
      e.preventDefault();

      if (!this.get('disabled')) {
        this.get('onClick')();
      }
    }
  });

  _exports.default = _default;
});