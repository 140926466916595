define("ember-bootstrap/components/base/bs-navbar/nav", ["exports", "ember-bootstrap/components/bs-nav"], function (_exports, _bsNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component for the `.nav` element within a [Components.Navbar](Components.Navbar.html)
   * component. Have a look there for examples.
   *
   * Per [the bootstrap docs](http://getbootstrap.com/components/#navbar),
   * justified navbar nav links are not supported.
   *
   * @class NavbarNav
   * @namespace Components
   * @extends Components.Nav
   * @public
   */
  var _default = _bsNav.default.extend({
    classNames: ['navbar-nav'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('justified', false);
    }
  });

  _exports.default = _default;
});