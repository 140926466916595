define("ember-bootstrap/components/base/bs-navbar/nav-item", ["exports", "ember-bootstrap/components/bs-nav/item"], function (_exports, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * @class NavbarNavItem
   * @namespace Components
   * @extends Components.NavItem
   * @public
   */
  var _default = _item.default.extend({
    /**
     * @property collapseNavbar
     * @type {Boolean}
     * @default true
     * @public
     */
    collapseNavbar: true,

    /**
     * @event onCollapse
     * @private
     */
    onCollapse: function onCollapse() {},
    click: function click() {
      if (this.get('collapseNavbar') && this.get('linkTo')) {
        this.onCollapse();
      }
    }
  });

  _exports.default = _default;
});