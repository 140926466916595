define("ember-bootstrap/components/bs-form", ["exports", "ember-bootstrap/components/base/bs-form"], function (_exports, _bsForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsForm.default.extend({
    layoutClass: Ember.computed('formLayout', function () {
      var layout = this.get('formLayout');
      return layout === 'inline' ? 'form-inline' : null;
    }).readOnly()
  });

  _exports.default = _default;
});