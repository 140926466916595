define("ember-bootstrap/components/bs-form/element/label", ["exports", "ember-bootstrap/components/base/bs-form/element/label"], function (_exports, _label) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _label.default.extend({
    tagName: 'label',
    classNames: [],
    classNameBindings: ['invisibleLabel:sr-only', 'isHorizontalAndNotCheckbox:col-form-label', 'isCheckbox:form-check-label', 'labelClass', 'sizeClass'],
    attributeBindings: ['formElementId:for'],
    isHorizontalAndNotCheckbox: Ember.computed('isHorizontal', 'isCheckbox', function () {
      return this.get('isHorizontal') && !this.get('isCheckbox');
    }),
    sizeClass: Ember.computed('size', 'isHorizontal', function () {
      if (!this.get('isHorizontal')) {
        return;
      }

      var size = this.get('size');
      return Ember.isBlank(size) ? null : "col-form-label-".concat(size);
    }),

    /**
     * Property for size styling, set to 'lg', 'sm'
     *
     * @property size
     * @type String
     * @public
     */
    size: null
  });

  _exports.default = _default;
});