define("ember-bootstrap/components/base/bs-form/element/control/input", ["exports", "ember-bootstrap/components/base/bs-form/element/control"], function (_exports, _control) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.baseAttributes = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var allowedTypes = new Map();

  function canUseType(type) {
    if ((typeof document === "undefined" ? "undefined" : _typeof(document)) !== 'object' || typeof document.createElement !== 'function') {
      // consider all types as supported if running in an
      // environment that doesn't support DOM
      return true;
    }

    if (!allowedTypes.has(type)) {
      try {
        var inputElement = document.createElement('input');
        inputElement.type = type;
        allowedTypes.set(type, true);
      } catch (error) {
        allowedTypes.set(type, false);
      }
    }

    return allowedTypes.get(type);
  }

  var baseAttributes = ['name', 'autofocus', 'disabled', 'readonly', 'required', 'tabindex', 'form', 'title', 'ariaDescribedBy:aria-describedby'];
  /**
  
   @class FormElementControlInput
   @namespace Components
   @extends Components.FormElementControl
   @private
   */

  _exports.baseAttributes = baseAttributes;

  var _default = _control.default.extend({
    tagName: 'input',
    attributeBindings: [].concat(baseAttributes, ['value', 'type', 'placeholder', 'controlSize:size', 'minlength', 'maxlength', 'min', 'max', 'pattern', 'accept', 'autocomplete', 'autocapitalize', 'autocorrect', 'autosave', 'inputmode', 'multiple', 'step', 'spellcheck']),
    classNames: ['form-control'],

    /**
     * @property type
     * @type {String}
     * @public
     */
    type: Ember.computed({
      get: function get() {
        return 'text';
      },
      set: function set(key, value) {
        // fallback to 'text' if value is empty
        if (Ember.isEmpty(value)) {
          return 'text';
        } // IE 11 throws if setting an unsupported type via DOM.
        // We guard against that behaviour by testing if user
        // agent throws on setting the provided type.
        // This is inspired by input helper shipped with Ember.js:
        // https://github.com/emberjs/ember.js/blob/30137796af42c63b28ead127cba0e43e45a773c1/packages/%40ember/-internals/glimmer/lib/components/text_field.ts#L93-L115


        if (!canUseType(value)) {
          return 'text';
        }

        return value;
      }
    }),
    change: function change(event) {
      this.get('onChange')(event.target.value);
    },
    input: function input(event) {
      this.get('onChange')(event.target.value);
    }
  });

  _exports.default = _default;
});