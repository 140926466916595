define("ember-ref-modifier/modifiers/ref", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function hasValidTarget(target) {
    return _typeof(target) === 'object' && target !== null && !Array.isArray(target);
  }

  function hasValidProperty(prop) {
    return typeof prop === 'string';
  }

  function getParams(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        maybeTarget = _ref2[0],
        maybePropName = _ref2[1];

    var isPropNameString = typeof maybePropName === 'string';

    if (!isPropNameString) {
      (true && !(false) && Ember.deprecate('ember-ref-modifier: {{ref "propertyName" context}} has been changed to {{ref context "propertyName"}}. Please migrate to use this.', false, {
        id: '@ember-ref-modifier--arguments-ordering-deprecation',
        until: 'v1.0.0'
      }));
    }

    return {
      propName: isPropNameString ? maybePropName : maybeTarget,
      target: isPropNameString ? maybeTarget : maybePropName
    };
  }

  var _default = Ember._setModifierManager(function () {
    return {
      createModifier: function createModifier() {
        return {
          element: undefined,
          propName: undefined,
          target: undefined
        };
      },
      installModifier: function installModifier(state, element, _ref3) {
        var positional = _ref3.positional;

        var _getParams = getParams(positional),
            propName = _getParams.propName,
            target = _getParams.target;

        if (hasValidProperty(propName) && hasValidTarget(target)) {
          Ember.set(target, propName, element);
          state.propName = propName;
          state.target = target;
        }

        state.element = element;
      },
      updateModifier: function updateModifier(state, _ref4) {
        var positional = _ref4.positional;

        var _getParams2 = getParams(positional),
            propName = _getParams2.propName,
            target = _getParams2.target;

        if (hasValidProperty(propName) && hasValidTarget(target)) {
          if (hasValidProperty(state.propName) && hasValidTarget(state.target)) {
            if (Ember.get(target, propName) !== Ember.get(state.target, state.propName)) {
              Ember.set(state.target, state.propName, null);
            }
          }

          Ember.set(target, propName, state.element);
          state.propName = propName;
          state.target = target;
        }
      },
      destroyModifier: function destroyModifier(_ref5) {
        var target = _ref5.target,
            propName = _ref5.propName;

        if (hasValidProperty(propName) && hasValidTarget(target)) {
          Ember.set(target, propName, null);
        }
      }
    };
  }, function OnModifier() {
    _classCallCheck(this, OnModifier);
  });

  _exports.default = _default;
});