define("ember-bootstrap/components/base/bs-accordion", ["exports", "ember-bootstrap/templates/components/bs-accordion", "ember-bootstrap/utils/cp/listen-to"], function (_exports, _bsAccordion, _listenTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Bootstrap-style [accordion group](http://getbootstrap.com/javascript/#collapse-example-accordion),
    with collapsible/expandable items.
  
    ### Usage
  
    Use as a block level component with any number of yielded [Components.AccordionItem](Components.AccordionItem.html)
    components as children:
  
    ```handlebars
    <BsAccordion as |Acc|>
      <Acc.item @value={{1}} @title="First item">
        <p>Lorem ipsum...</p>
        <button onclick={{action acc.change 2}}>Next</button>
      </Acc.item>
      <Acc.item @value={{2}} @title="Second item">
        <p>Lorem ipsum...</p>
      </Acc.item>
      <Acc.item @value={{3}} @title="Third item">
        <p>Lorem ipsum...</p>
      </Acc.item>
    </BsAccordion>
    ```
  
    In the example above the first accordion item utilizes the yielded `change` action to add some custom behaviour.
  
    @class Accordion
    @namespace Components
    @extends Ember.Component
    @public
  */
  var _default = Ember.Component.extend({
    layout: _bsAccordion.default,
    ariaRole: 'tablist',

    /**
     * The value of the currently selected accordion item. Set this to change selection programmatically.
     *
     * When the selection is changed by user interaction this property will not update by using two-way bindings in order
     * to follow DDAU best practices. If you want to react to such changes, subscribe to the `onChange` action
     *
     * @property selected
     * @public
     */
    selected: null,

    /**
     * @property itemComponent
     * @type {String}
     * @private
     */
    itemComponent: 'bs-accordion/item',

    /**
     * The value of the currently selected accordion item
     *
     * @property isSelected
     * @private
     */
    isSelected: (0, _listenTo.default)('selected'),

    /**
     * Action when the selected accordion item is about to be changed.
     *
     * You can return false to prevent changing the active item, and do that in your action by
     * setting the `selected` accordingly.
     *
     * @event onChange
     * @param newValue
     * @param oldValue
     * @public
     */
    onChange: function onChange(newValue, oldValue) {},
    // eslint-disable-line no-unused-vars
    actions: {
      change: function change(newValue) {
        var oldValue = this.get('isSelected');

        if (oldValue === newValue) {
          newValue = null;
        }

        if (this.get('onChange')(newValue, oldValue) !== false) {
          this.set('isSelected', newValue);
        }
      }
    }
  });

  _exports.default = _default;
});