define("ember-bootstrap/components/bs-form/element", ["exports", "ember-bootstrap/components/base/bs-form/element"], function (_exports, _element) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _element.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('doNotShowValidationForEventTargets', ['.input-group-append', '.input-group-prepend']);
    }
  });

  _exports.default = _default;
});