define("ember-bootstrap/components/bs-form/element/layout/horizontal", ["exports", "ember-bootstrap/components/base/bs-form/element/layout/horizontal"], function (_exports, _horizontal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _horizontal.default.extend({
    /**
     * Computed property that specifies the Bootstrap offset grid class for form controls within a horizontal layout
     * form, that have no label.
     *
     * @property horizontalInputOffsetGridClass
     * @type string
     * @readonly
     * @private
     */
    horizontalInputOffsetGridClass: Ember.computed('horizontalLabelGridClass', function () {
      if (Ember.isBlank(this.get('horizontalLabelGridClass'))) {
        return;
      }

      var parts = this.get('horizontalLabelGridClass').split('-');
      parts.splice(0, 1, 'offset');
      return parts.join('-');
    })
  });

  _exports.default = _default;
});