define("ember-bootstrap/components/base/bs-collapse", ["exports", "ember-bootstrap/utils/transition-end"], function (_exports, _transitionEnd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    An Ember component that mimics the behaviour of [Bootstrap's collapse.js plugin](http://getbootstrap.com/javascript/#collapse)
  
    ### Usage
  
    ```hbs
    <BsCollapse @collapsed={{this.collapsed}}>
      <div class="well">
        <h2>Collapse</h2>
        <p>This is collapsible content</p>
      </div>
    </BsCollapse>
    ```
  
    @class Collapse
    @namespace Components
    @extends Ember.Component
    @public
  */
  var _default = Ember.Component.extend({
    classNameBindings: ['collapse', 'collapsing'],

    /**
     * Collapsed/expanded state
     *
     * @property collapsed
     * @type boolean
     * @default true
     * @public
     */
    collapsed: true,

    /**
     * True if this item is expanded
     *
     * @property active
     * @private
     */
    active: false,
    collapse: Ember.computed.not('transitioning'),
    collapsing: Ember.computed.alias('transitioning'),
    showContent: Ember.computed.and('collapse', 'active'),

    /**
     * true if the component is currently transitioning
     *
     * @property transitioning
     * @type boolean
     * @private
     */
    transitioning: false,

    /**
     * The size of the element when collapsed. Defaults to 0.
     *
     * @property collapsedSize
     * @type number
     * @default 0
     * @public
     */
    collapsedSize: 0,

    /**
     * The size of the element when expanded. When null the value is calculated automatically to fit the containing elements.
     *
     * @property expandedSize
     * @type number
     * @default null
     * @public
     */
    expandedSize: null,

    /**
     * Usually the size (height) of the element is only set while transitioning, and reseted afterwards. Set to true to always set a size.
     *
     * @property resetSizeWhenNotCollapsing
     * @type boolean
     * @default true
     * @private
     */
    resetSizeWhenNotCollapsing: true,

    /**
     * The direction (height/width) of the collapse animation.
     * When setting this to 'width' you should also define custom CSS transitions for the width property, as the Bootstrap
     * CSS does only support collapsible elements for the height direction.
     *
     * @property collapseDimension
     * @type string
     * @default 'height'
     * @public
     */
    collapseDimension: 'height',

    /**
     * The duration of the fade transition
     *
     * @property transitionDuration
     * @type number
     * @default 350
     * @public
     */
    transitionDuration: 350,
    setCollapseSize: function setCollapseSize(size) {
      var dimension = this.get('collapseDimension');
      (true && !(["width", "height"].indexOf(dimension) !== -1) && Ember.assert("collapseDimension must be either \"width\" or \"height\". ".concat(dimension, " given."), ["width", "height"].indexOf(dimension) !== -1));
      this.element.style.width = dimension === 'width' && size ? "".concat(size, "px") : '';
      this.element.style.height = dimension === 'height' && size ? "".concat(size, "px") : '';
    },

    /**
     * The action to be sent when the element is about to be hidden.
     *
     * @event onHide
     * @public
     */
    onHide: function onHide() {},

    /**
     * The action to be sent after the element has been completely hidden (including the CSS transition).
     *
     * @event onHidden
     * @public
     */
    onHidden: function onHidden() {},

    /**
     * The action to be sent when the element is about to be shown.
     *
     * @event onShow
     * @public
     */
    onShow: function onShow() {},

    /**
     * The action to be sent after the element has been completely shown (including the CSS transition).
     *
     * @event onShown
     * @public
     */
    onShown: function onShown() {},

    /**
     * Triggers the show transition
     *
     * @method show
     * @protected
     */
    show: function show() {
      var _this = this;

      this.get('onShow')();
      this.setProperties({
        transitioning: true,
        active: true
      });
      this.setCollapseSize(this.get('collapsedSize'));
      (0, _transitionEnd.default)(this.get('element'), this.get('transitionDuration')).then(function () {
        if (_this.get('isDestroyed')) {
          return;
        }

        _this.set('transitioning', false);

        if (_this.get('resetSizeWhenNotCollapsing')) {
          _this.setCollapseSize(null);
        }

        _this.get('onShown')();
      });
      Ember.run.next(this, function () {
        if (!this.get('isDestroyed')) {
          this.setCollapseSize(this.getExpandedSize('show'));
        }
      });
    },

    /**
     * Get the size of the element when expanded
     *
     * @method getExpandedSize
     * @param action
     * @return {Number}
     * @private
     */
    getExpandedSize: function getExpandedSize(action) {
      var expandedSize = this.get('expandedSize');

      if (Ember.isPresent(expandedSize)) {
        return expandedSize;
      }

      var collapseElement = this.get('element');
      var prefix = action === 'show' ? 'scroll' : 'offset';
      var measureProperty = Ember.String.camelize("".concat(prefix, "-").concat(this.get('collapseDimension')));
      return collapseElement[measureProperty];
    },

    /**
     * Triggers the hide transition
     *
     * @method hide
     * @protected
     */
    hide: function hide() {
      var _this2 = this;

      this.get('onHide')();
      this.setProperties({
        transitioning: true,
        active: false
      });
      this.setCollapseSize(this.getExpandedSize('hide'));
      (0, _transitionEnd.default)(this.get('element'), this.get('transitionDuration')).then(function () {
        if (_this2.get('isDestroyed')) {
          return;
        }

        _this2.set('transitioning', false);

        if (_this2.get('resetSizeWhenNotCollapsing')) {
          _this2.setCollapseSize(null);
        }

        _this2.get('onHidden')();
      });
      Ember.run.next(this, function () {
        if (!this.get('isDestroyed')) {
          this.setCollapseSize(this.get('collapsedSize'));
        }
      });
    },
    _onCollapsedChange: Ember.observer('collapsed', function () {
      var collapsed = this.get('collapsed');
      var active = this.get('active');

      if (collapsed !== active) {
        return;
      }

      if (collapsed === false) {
        this.show();
      } else {
        this.hide();
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('active', !this.get('collapsed'));
    },
    _updateCollapsedSize: Ember.observer('collapsedSize', function () {
      if (!this.get('resetSizeWhenNotCollapsing') && this.get('collapsed') && !this.get('collapsing')) {
        this.setCollapseSize(this.get('collapsedSize'));
      }
    }),
    _updateExpandedSize: Ember.observer('expandedSize', function () {
      if (!this.get('resetSizeWhenNotCollapsing') && !this.get('collapsed') && !this.get('collapsing')) {
        this.setCollapseSize(this.get('expandedSize'));
      }
    })
  });

  _exports.default = _default;
});