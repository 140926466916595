define("ember-bootstrap/components/base/bs-navbar/link-to", ["exports", "ember-bootstrap/components/bs-nav/link-to"], function (_exports, _linkTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extended `{{link-to}}` component for use within Navbars.
   *
   * @class NavbarLinkTo
   * @namespace Components
   * @extends Components.NavLinkTo
   * @public
   */
  var _default = _linkTo.default.extend({
    /**
     * @property collapseNavbar
     * @type {Boolean}
     * @default true
     * @public
     */
    collapseNavbar: true,

    /**
     * @event onCollapse
     * @private
     */
    onCollapse: function onCollapse() {},
    click: function click() {
      if (this.get('collapseNavbar')) {
        this.onCollapse();
      }
    }
  });

  _exports.default = _default;
});