define("ember-bootstrap/components/base/bs-form/element/control/textarea", ["exports", "ember-bootstrap/components/base/bs-form/element/control", "ember-bootstrap/components/base/bs-form/element/control/input"], function (_exports, _control, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  /**
  
   @class FormElementControlTextarea
   @namespace Components
   @extends Components.FormElementControl
   @private
   */
  var _default = _control.default.extend({
    attributeBindings: [].concat(_toConsumableArray(_input.baseAttributes), ['value', 'placeholder', 'minlength', 'maxlength', 'autocomplete', 'autocapitalize', 'autocorrect', 'spellcheck', 'rows', 'cols', 'wrap']),
    tagName: 'textarea',
    classNames: ['form-control'],
    change: function change(event) {
      this.get('onChange')(event.target.value);
    },
    input: function input(event) {
      this.get('onChange')(event.target.value);
    }
  });

  _exports.default = _default;
});